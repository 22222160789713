import "./assets/css/bulma/bulma.sass";
import "./assets/css/main.scss";

import Header from "./sub-components/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Footer from "./sub-components/Footer";
import Enterprises from "./pages/enterprises";
import D2C from "./pages/d2c";
import Customers from "./pages/customer";
import MSME from "./pages/msme";
import PrivacyAgentApp from "./pages/misc/privacy-agent-app";
import TermsCondition from "./pages/misc/terms-condition";
import Privacy from "./pages/misc/privacy";
import ContactUs from "./pages/misc/contact-us";
import DeleteAccount from "./pages/misc/delete-account";
import DeleteAgentAccount from "./pages/misc/delete-agent-account";

function App() {
  return (
    <div className="App">
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/enterprises" element={<Enterprises />} />
          <Route path="/d2c" element={<D2C />} />
          <Route path="/customer" element={<Customers />} />
          <Route path="/msme" element={<MSME />} />


          <Route path="/privacy-agent-app" element={<PrivacyAgentApp />} />
          <Route path="/terms-and-condition" element={<TermsCondition />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/delete-agent-account" element={<DeleteAgentAccount />} />

        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
